import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const RecentPosts = makeShortcode("RecentPosts");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hello`}</h1>
    <p>{`I'm Jamie, a software engineer, born and bred in North East England.`}</p>
    <p>{`I've been building websites, and applications since 1999, and been around long enough to remember the days I built preloaders for Flash websites.`}</p>
    <p>{`Thankfully those days are gone, and you'll `}<a parentName="p" {...{
        "href": "/now"
      }}>{`now`}</a>{` find me working with headless APIs, JavaScript, React, GraphQL, and most recently Go.`}</p>
    <p>{`If you're interested in any of the above, or what I'm up to, take a look at some of my recent posts below.`}</p>
    <RecentPosts mdxType="RecentPosts" />
    <p>{`If you're interested in working together on a project, get in touch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      